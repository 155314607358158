import { getFirebaseAuthInfra } from "@/infrastructure/firebase_auth/client"
import { FirebaseStorageInfra } from "@/infrastructure/firebase_storage/core/storage"
import { getFirebaseApp } from "@/infrastructure/firebase/client"
import { getStorage } from "firebase/storage"

export function getFirebaseStorageInfra(): FirebaseStorageInfra {
  const firebaseApp = getFirebaseApp()

  // 認証状態は初期化する必要があるので、実行しておく
  getFirebaseAuthInfra()

  return new FirebaseStorageInfra(getStorage(firebaseApp))
}
