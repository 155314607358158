import Link from "next/link"
import { usePathname } from "next/navigation"
import { cn } from "@/common/utils/utils"
import { type LucideIcon } from "lucide-react"

import { pagesPath } from "@/config/$path"

import NavbarBadge from "./NavbarBadge"

type Props = {
  icon: LucideIcon
  label: string
  relativePath: string
  notificationCount?: number
}

const NavbarItem: React.FC<Props> = ({
  icon: Icon,
  label,
  relativePath,
  notificationCount,
}) => {
  const path = usePathname()
  const companyId = path?.split("/")[1]
  const href = `${pagesPath._companyId(companyId!).$url().path}${relativePath !== "" ? `/${relativePath}` : ""}`

  const isActive = path === href

  const baseClasses = `
    flex
    cursor-pointer
    items-center
    justify-between
    px-4
    py-2
    text-foreground
    text-sm
    hover:rounded-md
    hover:bg-accent
    md:w-auto
    h-10
  `

  return (
    <Link
      href={href}
      className={cn(baseClasses, isActive && "rounded-md bg-accent")}
    >
      <div className="flex items-center gap-x-4">
        <Icon size={16} />
        <span className="truncate text-sm">{label}</span>
      </div>
      {notificationCount && notificationCount !== 0 && (
        <NavbarBadge count={notificationCount} />
      )}
    </Link>
  )
}

export default NavbarItem
