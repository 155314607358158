"use client"

import { useEffect, useState } from "react"
import Link from "next/link"
import { usePathname } from "next/navigation"
import { type Company } from "@/domain/features/company/v2/entities/company/company"
import { CompanyAdminRepository } from "@/domain/features/company/v2/repositories/company_admin_repository"
import { CompanyRepository } from "@/domain/features/company/v2/repositories/company_repository"
import { getFirebaseFirestoreInfra } from "@/infrastructure/firebase_firestore/client"

import { pagesPath } from "@/config/$path"
import { MainNav } from "@/app/_components/molecules/layouts/MainNav"
import CloudStorageImage from "@/app/_components/organisms/image/CloudStorageImage"
import { usePendingAttendanceApplications } from "@/app/_hooks/usePendingAttendanceApplications"
import { usePendingCompanyApplicationCount } from "@/app/_hooks/usePendingCompanyApplicationCount"

import MobileNav from "../../molecules/layouts/MobileNav"

type SiteHeaderProps = {
  company: Company | undefined
  userId: string
}

const SiteHeader = (props: SiteHeaderProps) => {
  const [company, setCompany] = useState<Company | undefined>(props.company)
  const [isAdmin, setIsAdmin] = useState<boolean>(false)
  const companyId = props.company?.companyId

  const path = usePathname()
  const companyIdPath = path?.split("/")[1]
  const href = `${pagesPath._companyId(companyIdPath!).$url().path}`

  useEffect(() => {
    if (companyId == null) {
      return () => {}
    }
    const firestore = getFirebaseFirestoreInfra()
    const companyRepository = new CompanyRepository(firestore)

    const unsubscribe = companyRepository.onSnapshot({
      companyId,
      onData: (company) => {
        setCompany(company)
      },
    })

    return () => {
      unsubscribe()
    }
  }, [companyId])

  useEffect(() => {
    if (props.userId == undefined || props.company?.companyId == null) {
      return () => {}
    }
    const firestore = getFirebaseFirestoreInfra()
    const companyAdminRepository = new CompanyAdminRepository(firestore)

    const unsubscribe = companyAdminRepository.onSnapshot({
      params: { companyId: props.company.companyId },
      userId: props.userId,
      onData: (exists) => {
        setIsAdmin(exists)
      },
    })

    return () => {
      unsubscribe()
    }
  }, [props.userId, props.company?.companyId])

  const pendingCompanyApplicationsCount = usePendingCompanyApplicationCount({
    companyId: props.company?.companyId,
  }).pendingApplicationCount
  const pendingAttendanceApplicationsCount = usePendingAttendanceApplications(
    props.company?.companyId
  ).length

  if (!company) return <></>

  return (
    <header className="md:w-[240px]">
      <div className="container flex h-16 w-full items-center">
        <MainNav
          isAdmin={isAdmin}
          pendingCompanyApplicationsCount={pendingCompanyApplicationsCount}
          pendingAttendanceApplicationsCount={
            pendingAttendanceApplicationsCount
          }
        >
          <Link href={href}>
            <div className="flex items-center justify-center gap-2">
              <div className="inline-flex items-start justify-center rounded-md border border-input bg-background p-1">
                <CloudStorageImage
                  path={company.imageStoragePath}
                  imageType="company"
                  alt="CompanyImage"
                  width={32}
                  height={32}
                />
              </div>
              <h2 className="truncate text-lg font-bold text-foreground">
                {company.name}
              </h2>
            </div>
          </Link>
        </MainNav>
        <MobileNav
          isAdmin={isAdmin}
          pendingApplicationsCount={pendingCompanyApplicationsCount}
          pendingAttendanceApplicationsCount={
            pendingAttendanceApplicationsCount
          }
        >
          <Link href={href}>
            <div className="flex items-center gap-1 p-2">
              <div className="inline-flex items-start justify-center rounded-md border border-input bg-background p-1">
                <CloudStorageImage
                  path={company.imageStoragePath}
                  imageType="company"
                  alt="CompanyImage"
                  width={32}
                  height={32}
                />
              </div>
              <h2 className="truncate text-lg font-bold text-foreground">
                {company.name}
              </h2>
            </div>
          </Link>
        </MobileNav>
      </div>
    </header>
  )
}

export default SiteHeader
