import { type PaidLeaveMetadata } from "@/domain/features/company/v2/entities/attendance_confirmed_record/attendance_confirmed_record"

import { type Attendance } from "../attendance/attendance"

/**
 * 打刻に関する申請
 */
type AttendanceApplications = [
  EditAttendanceApplication,
  AbsenceApplication,
  PaidLeaveApplication,
  OvertimeAttendanceApplication,
  HolidayWorkApplication,
]

export type AttendanceApplication = AttendanceApplications[number]

/**
 * 打刻修正申請
 */
export interface EditAttendanceApplication extends BaseAttendanceApplication {
  type: "editAttendance"
  change: AttendanceChange
}

/**
 * 欠勤申請
 */
export interface AbsenceApplication extends BaseAttendanceApplication {
  type: "absence"
  change: undefined
}

/**
 * 変則的な日付申請
 */
export interface PaidLeaveApplication extends BaseAttendanceApplication {
  type: "paidLeave"
  change: AttendanceChange
  paidLeaveDetails: PaidLeaveMetadata
}

/**
 * 残業申請
 */
export interface OvertimeAttendanceApplication
  extends BaseAttendanceApplication {
  type: "overtime"
  change: AttendanceChange
}

/**
 * 休日出勤申請
 */
export interface HolidayWorkApplication extends BaseAttendanceApplication {
  type: "holidayWork"
  holidayWorkDetails: HolidayWorkApplicationDetails
  change: AttendanceChange
}

/**
 * 休日出勤申請設定
 */
export interface HolidayWorkApplicationDetails {
  holidayType: HolidayType
  alternativeHoliday?: {
    type: "substituted" | "compensatory"
    date: Date
  }
}

export type HolidayType = (typeof HolidayType.values)[number]
export namespace HolidayType {
  export const values = ["companySpecific", "legal"] as const
  export const format = (holidayType: HolidayType) => {
    switch (holidayType) {
      case "companySpecific":
        return "所定休日"
      case "legal":
        return "法定休日"
    }
  }
}

export type AlternativeHolidayType =
  (typeof AlternativeHolidayType.values)[number]

export namespace AlternativeHolidayType {
  export const values = ["substituted", "compensatory"] as const
  export const format = (
    alternativeHolidayType: AlternativeHolidayType | undefined | null
  ) => {
    if (alternativeHolidayType == null) {
      return "選択しない"
    }
    switch (alternativeHolidayType) {
      case "substituted":
        return "振替休日"
      case "compensatory":
        return "代休"
    }
  }
}
export type AlternativeHolidaySelectionType =
  (typeof AlternativeHolidaySelectionType.values)[number]

export namespace AlternativeHolidaySelectionType {
  export const values = ["substituted", "compensatory", "<clear>"] as const

  export const format = (
    alternativeHolidayType: AlternativeHolidaySelectionType
  ) => {
    switch (alternativeHolidayType) {
      case "<clear>":
        return "選択しない"
      case "substituted":
        return "振替休日"
      case "compensatory":
        return "代休"
    }
  }

  export const toEntity = (
    alternativeHolidayType: AlternativeHolidaySelectionType
  ): AlternativeHolidayType | undefined => {
    switch (alternativeHolidayType) {
      case "<clear>":
        return undefined
      case "substituted":
        return "substituted"
      case "compensatory":
        return "compensatory"
    }
  }
}

interface BaseAttendanceApplication {
  type: AttendanceApplicationType
  applicationId: string
  createdAt: Date
  targetDate: Date
  change: AttendanceChange | undefined
  holidayWorkDetails: HolidayWorkApplicationDetails | undefined
  paidLeaveDetails: PaidLeaveMetadata | undefined
  status: AttendanceApplicationStatus
  isPending: boolean
  applicationComment: string | undefined
  attendanceMemo: string | undefined
  rejectionReason: string | undefined
}

export namespace AttendanceApplication {
  export namespace firestore {
    export function collectionPath({
      userId,
      companyId,
    }: {
      userId: string
      companyId: string
    }) {
      return `Company/v2/users/${userId}/workingCompanies/${companyId}/attendance/doc/applications/`
    }
    export type ParameterOfCollectionPath = Parameters<typeof collectionPath>[0]
  }
}

export type AttendanceApplicationType =
  (typeof AttendanceApplicationType.values)[number]
export namespace AttendanceApplicationType {
  export type Tense =
    | "past"
    | "today_beforeCheckIn"
    | "today_working"
    | "today_afterCheckOut"
    | "future"
  export const values = [
    "editAttendance",
    "absence",
    "paidLeave",
    "overtime",
    "holidayWork",
  ] as const

  export function format(type: AttendanceApplicationType) {
    switch (type) {
      case "editAttendance":
        return "打刻修正"
      case "absence":
        return "欠勤"
      case "paidLeave":
        return "有休"
      case "overtime":
        return "早出・残業"
      case "holidayWork":
        return "休日出勤"
    }
  }

  export function getValues({
    tense,
    isOvertimeApplicationSystemEnabled,
  }: {
    tense: Tense
    isOvertimeApplicationSystemEnabled: boolean
  }): AttendanceApplicationType[] {
    const list: Set<AttendanceApplicationType> = new Set()

    values.forEach((value) => {
      const push = () => {
        list.add(value)
      }
      switch (value) {
        case "editAttendance":
          switch (tense) {
            case "past":
            case "future":
              push()
            case "today_beforeCheckIn":
            case "today_working":
            case "today_afterCheckOut":
              break
          }
        case "absence":
        case "paidLeave":
          switch (tense) {
            case "past":
            case "future":
            case "today_beforeCheckIn":
            case "today_afterCheckOut":
              push()
            case "today_working":
              break
          }
          break
        case "overtime":
          if (isOvertimeApplicationSystemEnabled) {
            push()
          }
        case "holidayWork":
          push()
      }
    })

    return [...list]
  }
}

export type AttendanceApplicationStatus = "pending" | "approved" | "rejected"

/**
 * before/after を持った打刻情報
 */
export interface AttendanceChange {
  after: Attendance[]
  before?: Attendance[]
}
