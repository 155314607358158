"use client"

import * as React from "react"
import Image from "next/image"
import Link from "next/link"
import { usePathname } from "next/navigation"
import { cn } from "@/common/utils/utils"
import {
  BarChart2,
  Building,
  Clock,
  FileCheck,
  FileText,
  List,
  Menu,
  Palmtree,
  User2,
  Users,
} from "lucide-react"

import { siteConfig } from "@/config/site"
import { Button } from "@/app/_components/atoms/button"
import { ScrollArea } from "@/app/_components/atoms/scroll-area"
import { Separator } from "@/app/_components/atoms/separator"
import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from "@/app/_components/atoms/sheet"

import NavbarBadge from "../../atoms/NavbarBadge"

type Props = {
  children: React.ReactNode
  isAdmin: boolean
  pendingApplicationsCount: number
  pendingAttendanceApplicationsCount: number
}

const MobileNav = ({
  children,
  isAdmin,
  pendingApplicationsCount,
  pendingAttendanceApplicationsCount,
}: Props) => {
  const [isOpen, setIsOpen] = React.useState(false)
  const icons: Record<string, typeof List> = {
    勤務状況: List,
    残業状況: BarChart2,
    申請承認: FileCheck,
    メンバー管理: Users,
    管理者設定: Building,
    有休管理: Palmtree,
    打刻: Clock,
    "履歴・修正": FileText,
    マイページ: User2,
  }

  const notificationCounts: Record<string, number | undefined> = {
    勤務状況: undefined,
    残業状況: undefined,
    申請承認: pendingAttendanceApplicationsCount,
    メンバー管理: pendingApplicationsCount,
    管理者設定: undefined,
    有休管理: undefined,
    打刻: undefined,
    "履歴・修正": undefined,
    マイページ: undefined,
  }

  return (
    <Sheet open={isOpen} onOpenChange={setIsOpen}>
      <SheetTrigger asChild>
        <Button
          variant="ghost"
          className="mr-2 px-0 text-base hover:bg-transparent focus-visible:bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 md:hidden"
        >
          <Menu className="size-6" />
          <span className="sr-only">Toggle Menu</span>
        </Button>
      </SheetTrigger>
      <SheetContent side="left" className="p-0">
        {children}
        <Separator className="mx-auto w-full" />
        <ScrollArea className="flex flex-1 flex-col justify-stretch space-y-4 overflow-y-auto">
          <ul className="flex flex-col space-y-2">
            {siteConfig.mainNav?.map((item, index) => {
              const icon = icons[item.title] || Clock

              return (
                <li key={item.title} className="w-full px-4">
                  <MobileLink
                    label={item.title}
                    key={index}
                    relativePath={item.relativePath}
                    setIsOpen={setIsOpen}
                    icon={icon}
                  />
                </li>
              )
            })}
            {isAdmin && (
              <>
                <Separator className="mx-auto w-full" />
                <li className="w-full">
                  <span className="pl-8 text-sm font-bold text-foreground">
                    管理者
                  </span>
                </li>
                {siteConfig.adminNav.map((item, index) => {
                  const notificationCount =
                    notificationCounts[item.title] || undefined
                  const icon = icons[item.title] || List

                  return (
                    <li key={item.title} className="w-full px-4">
                      <MobileLink
                        label={item.title}
                        key={index}
                        relativePath={item.relativePath}
                        setIsOpen={setIsOpen}
                        icon={icon}
                        notificationCount={notificationCount}
                      />
                    </li>
                  )
                })}
              </>
            )}
          </ul>
        </ScrollArea>

        <div className="fixed bottom-0 flex w-3/4 justify-between p-4 sm:max-w-sm">
          <Image
            src="/logo-and-horizontal-text.svg"
            alt="logo"
            width={120}
            height={32}
          />
          {/* <ModeToggle /> */}
        </div>
      </SheetContent>
    </Sheet>
  )
}

export default MobileNav

interface MobileLinkProps {
  label: string
  relativePath: string
  disabled?: boolean
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  icon: typeof Clock
  notificationCount?: number
}

const MobileLink = ({
  label,
  relativePath,
  disabled,
  setIsOpen,
  icon: Icon,
  notificationCount,
}: MobileLinkProps) => {
  const baseClasses = `
    flex
    cursor-pointer
    items-center
    justify-between
    px-4
    py-2
    text-foreground
    text-sm
    hover:rounded-md
    hover:bg-neutral-100
    md:w-auto
    h-10
  `
  const companyId = usePathname()?.split("/")[1]
  const href = `/${companyId}${relativePath !== "" ? `/${relativePath}` : ""}`
  const isActive = href === usePathname()

  return (
    <Link
      href={href}
      className={cn(
        baseClasses,
        isActive && "rounded-md bg-accent",
        disabled && "pointer-events-none opacity-60"
      )}
      onClick={() => setIsOpen(false)}
    >
      <div className="flex items-center gap-x-4">
        <Icon size={16} />
        <span className="truncate text-sm">{label}</span>
      </div>

      {notificationCount && notificationCount !== 0 && (
        <NavbarBadge count={notificationCount} />
      )}
    </Link>
  )
}
