import { useEffect, useState } from "react"
import { JoiningCompanyApplicationRepository } from "@/domain/features/company/v2/repositories/joining_company_application_repository"
import { getFirebaseFirestoreInfra } from "@/infrastructure/firebase_firestore/client"

type Props = {
  companyId: string | undefined
}

export const usePendingCompanyApplicationCount = ({ companyId }: Props) => {
  const [pendingApplicationCount, setPendingApplicationCount] =
    useState<number>(0)
  useEffect(() => {
    if (companyId == null) {
      return () => {}
    }
    const fetchPendingApplications = async () => {
      if (companyId == null) {
        return
      }
      const firestore = getFirebaseFirestoreInfra()
      const joiningCompanyApplicationRepository =
        new JoiningCompanyApplicationRepository(firestore)

      const pendingApplications =
        await joiningCompanyApplicationRepository.getPendingApplications({
          companyId,
        })
      setPendingApplicationCount(pendingApplications.length)
    }
    // todo: Implement error handling
    fetchPendingApplications().catch(() => {})
  }, [companyId])

  return { pendingApplicationCount }
}
