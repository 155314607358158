"use client"

import { useEffect, useState } from "react"
import Image from "next/image"
import { getFirebaseStorageInfra } from "@/infrastructure/firebase_storage/client"
import { getDownloadURL } from "firebase/storage"

type Props = {
  path?: string
  height?: number
  width?: number
  className?: string
  alt: string
  priority?: boolean
  imageType: ImageType
}

type ImageType = "company"

const placeholder = (imageType: ImageType) => {
  switch (imageType) {
    case "company":
      return "/company-placeholder.svg"
  }
}

const CloudStorageImage = ({
  path,
  height,
  width,
  className,
  alt,
  imageType,
}: Props) => {
  const [src, setSrc] = useState<string>(placeholder(imageType))
  useEffect(() => {
    if (path == null) {
      setSrc(placeholder(imageType))
    } else {
      const getUrl = async () => {
        const storage = getFirebaseStorageInfra()
        const ref = storage.ref(path)

        const downloadUrl = await getDownloadURL(ref)
        setSrc(downloadUrl)
      }

      void getUrl()
    }
  }, [imageType, path, setSrc])

  return (
    <Image
      src={src}
      alt={alt}
      height={height}
      width={width}
      className={className}
      priority
    ></Image>
  )
}

export default CloudStorageImage
