import {
  JoiningCompanyApplication,
  type JoiningCompanyApplicationStatus,
} from "@/domain/features/company/v2/entities/joining_company_application/joining_company_application"
import { type FirebaseFirestoreInfra } from "@/infrastructure/firebase_firestore/core/firestore"
import { type Unsubscribe } from "firebase/auth"
import {
  doc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  serverTimestamp,
  setDoc,
  where,
  type QueryConstraint,
} from "firebase/firestore"

export class JoiningCompanyApplicationRepository {
  constructor(private readonly firestore: FirebaseFirestoreInfra) {}

  private collectionReference(
    params: JoiningCompanyApplication.firestore.ParameterOfCollectionPath
  ) {
    return this.firestore.collectionReference<JoiningCompanyApplication>(
      JoiningCompanyApplication.firestore.collectionPath(params)
    )
  }

  private documentReference(
    params: JoiningCompanyApplication.firestore.ParameterOfDocumentPath
  ) {
    return this.firestore.documentReference<JoiningCompanyApplication>(
      JoiningCompanyApplication.firestore.documentPath(params)
    )
  }

  private collectionGroupReference() {
    return this.firestore.collectionGroupReference<JoiningCompanyApplication>(
      JoiningCompanyApplication.firestore.collectionId
    )
  }

  async save({
    userId,
    companyId,
    firstName,
    lastName,
  }: {
    userId: string
    companyId: string
    firstName: string
    lastName: string
  }): Promise<void> {
    const collectionReference = this.collectionReference({ userId })
    const documentReference = doc(collectionReference)
    await setDoc(documentReference, {
      joiningCompanyApplicationId: documentReference.id,
      status: "pending",
      companyId,
      firstName,
      lastName,
      createdAt: serverTimestamp(),
      updatedAt: serverTimestamp(),
    })
  }

  watchList({
    userId,
    onData,
    status,
    limit: paramsLimit,
  }: {
    userId: string
    onData: (data: JoiningCompanyApplication[]) => void
    status?: JoiningCompanyApplicationStatus[]
    limit?: number
  }): Unsubscribe {
    const queryConstraints: QueryConstraint[] = []

    queryConstraints.push(
      orderBy("createdAt" satisfies keyof JoiningCompanyApplication, "desc")
    )

    if (status != null) {
      queryConstraints.push(
        where("status" satisfies keyof JoiningCompanyApplication, "in", status)
      )
    }

    if (paramsLimit != null) {
      queryConstraints.push(limit(paramsLimit))
    }

    const ref = query(this.collectionReference({ userId }), ...queryConstraints)

    return onSnapshot(ref, (snapshot) => {
      onData(snapshot.empty ? [] : snapshot.docs.map((s) => s.data()))
    })
  }

  async existsPendingApplications({
    companyId,
  }: {
    companyId: string
  }): Promise<boolean> {
    const collectionGroupReference = this.collectionGroupReference()
    const ref = query(
      collectionGroupReference,
      where(
        "companyId" satisfies keyof JoiningCompanyApplication,
        "==",
        companyId
      ),
      where(
        "status" satisfies keyof JoiningCompanyApplication,
        "==",
        "pending" satisfies JoiningCompanyApplicationStatus
      ),
      orderBy("createdAt" satisfies keyof JoiningCompanyApplication, "desc"),
      limit(1)
    )

    const snapshot = await getDocs(ref)

    return snapshot.size !== 0
  }

  async getPendingApplications({ companyId }: { companyId: string }): Promise<
    (JoiningCompanyApplication & {
      userId: string
    })[]
  > {
    const collectionGroupReference = this.collectionGroupReference()
    const ref = query(
      collectionGroupReference,
      where(
        "companyId" satisfies keyof JoiningCompanyApplication,
        "==",
        companyId
      ),
      where(
        "status" satisfies keyof JoiningCompanyApplication,
        "==",
        "pending" satisfies JoiningCompanyApplicationStatus
      ),
      orderBy("createdAt" satisfies keyof JoiningCompanyApplication, "desc")
    )

    const snapshot = await getDocs(ref)

    if (snapshot.size === 0) {
      return []
    }

    return snapshot.docs.map((e) => {
      const data = e.data()

      return {
        userId: e.ref.path.split("/")[3]!,
        joiningCompanyApplicationId: data.joiningCompanyApplicationId,
        status: data.status,
        companyId: data.companyId,
        createdAt: data.createdAt,
        updatedAt: data.updatedAt,
        firstName: data.firstName,
        lastName: data.lastName,
      }
    })
  }

  async rejectApplication(
    params: JoiningCompanyApplication.firestore.ParameterOfDocumentPath
  ): Promise<void> {
    const documentRef = this.documentReference(params)

    await setDoc(documentRef, { status: "rejected" }, { merge: true })
  }
}
