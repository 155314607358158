import { type MainNavItem } from "@/app/[companyId]/(admin)/admin-settings/_types"

const projectId: NEXT_PUBLIC_FIREBASE_PROJECT_ID = process.env
  .NEXT_PUBLIC_FIREBASE_PROJECT_ID as NEXT_PUBLIC_FIREBASE_PROJECT_ID

export type NEXT_PUBLIC_FIREBASE_PROJECT_ID =
  | "kanril-dev"
  | "kanril-prod"
  | "kanril-stg"

function getSiteUrl(projectId: NEXT_PUBLIC_FIREBASE_PROJECT_ID) {
  switch (projectId) {
    case "kanril-dev":
      return "http://localhost:3000"
    case "kanril-stg":
      return "https://web-stg.kanril.net"
    case "kanril-prod":
      return "https://web.kanril.net/"
    default:
      return "http://localhost:3000"
  }
}

function getAdminUrl(projectId: NEXT_PUBLIC_FIREBASE_PROJECT_ID) {
  switch (projectId) {
    case "kanril-dev":
      return "https://v2-kanril-dev.firebaseapp.com"
    case "kanril-stg":
      return "https://kanril-stg-admin.firebaseapp.com/login"
    case "kanril-prod":
      return "https://kanril-prod.web.app"
    default:
      return "https://v2-kanril-dev.firebaseapp.com"
  }
}

const env = <T>(cb: (projectId: NEXT_PUBLIC_FIREBASE_PROJECT_ID) => T) => {
  return cb(projectId)
}

export const STAGE = env((projectId) => {
  switch (projectId) {
    case "kanril-dev":
      return "dev"
    case "kanril-stg":
      return "stg"
    case "kanril-prod":
      return "prod"
    default:
      return "dev"
  }
})

export const ADMIN_URL = env(getAdminUrl)

export type SiteConfig = typeof siteConfig

export const siteConfig = {
  name: "KANRIL Web",
  description: "KANRIL Web",
  url: env(getSiteUrl),
  mainNav: [
    {
      title: "打刻",
      relativePath: "",
    },
    {
      title: "履歴・修正",
      relativePath: `history`,
    },
    {
      title: "マイページ",
      relativePath: "my-page",
    },
  ] satisfies MainNavItem[],
  adminNav: [
    {
      title: "勤務状況",
      relativePath: "work-status",
    },
    {
      title: "残業状況",
      relativePath: "overtime-status",
    },
    {
      title: "有休管理",
      relativePath: "paid-leave",
    },
    {
      title: "申請承認",
      relativePath: "approval-request",
    },
    {
      title: "メンバー管理",
      relativePath: "member-management",
    },
    {
      title: "データ出力",
      relativePath: "data-export",
    },
    {
      title: "管理者設定",
      relativePath: "admin-settings",
    },
  ] satisfies MainNavItem[],
}
