import { useEffect, useState } from "react"
import { type AttendanceApplication } from "@/domain/features/company/v2/entities/attendance_application/attendance_application"
import { type RedundantFromWorker } from "@/domain/features/company/v2/entities/redundant_from_worker/redundant_from_worker"
import { AttendanceApplicationRepository } from "@/domain/features/company/v2/repositories/attendance_application_repository"
import { getFirebaseFirestoreInfra } from "@/infrastructure/firebase_firestore/client"

const usePendingAttendanceApplications = (companyId: string | undefined) => {
  const [pendingAttendanceApplications, setPendingAttendanceApplications] =
    useState<RedundantFromWorker<AttendanceApplication>[]>([])
  useEffect(() => {
    if (companyId == null) {
      return () => {}
    }
    const getPendingAttendanceApplications = async () => {
      const firestore = getFirebaseFirestoreInfra()

      const allCompanyApplications = await new AttendanceApplicationRepository(
        firestore
      ).listInCompany({
        companyId,
      })
      const pendingApplications = allCompanyApplications.filter(
        (application) => application.data.isPending
      )
      setPendingAttendanceApplications(pendingApplications)
    }
    getPendingAttendanceApplications().catch(() => {})
  }, [companyId])

  return pendingAttendanceApplications
}

export { usePendingAttendanceApplications }
