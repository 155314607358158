import type * as common from "../attendance_application/attendance_application"
import { type WorkerProfile } from "../worker_profile/worker_profile"

/**
 * user コレクションのサブコレクションから、company へ冗長化された時のデータの形
 */
export interface RedundantFromWorker<T> {
  userId: string
  profile: WorkerProfile
  data: T
}

export namespace RedundantFromWorker {
  /**
   * 打刻申請
   */
  export type AttendanceApplication =
    RedundantFromWorker<common.AttendanceApplication>
  export namespace AttendanceApplication {
    export namespace firestore {
      export const collectionPath = ({ companyId }: { companyId: string }) =>
        `Company/v2/companies/${companyId}/attendance/doc/applications`

      export type ParameterOfCollectionPath = Parameters<
        typeof collectionPath
      >[0]
    }
  }
}
