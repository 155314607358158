"use client"

import * as React from "react"
import Image from "next/image"
import {
  BarChart2,
  Building,
  Clock,
  FileCheck,
  FileOutput,
  FileText,
  List,
  Palmtree,
  User2,
  Users,
} from "lucide-react"

import { siteConfig } from "@/config/site"

import NavbarItem from "../../atoms/NavbarItem"
import { Separator } from "../../atoms/separator"

type Props = {
  children: React.ReactNode
  isAdmin: boolean
  pendingCompanyApplicationsCount: number
  pendingAttendanceApplicationsCount: number
}

export function MainNav({
  children,
  isAdmin,
  pendingCompanyApplicationsCount,
  pendingAttendanceApplicationsCount,
}: Props) {
  const icons: Record<string, typeof List> = {
    勤務状況: List,
    残業状況: BarChart2,
    申請承認: FileCheck,
    メンバー管理: Users,
    データ出力: FileOutput,
    管理者設定: Building,
    有休管理: Palmtree,
    打刻: Clock,
    "履歴・修正": FileText,
    マイページ: User2,
  }

  const notificationCounts: Record<string, number | undefined> = {
    勤務状況: undefined,
    残業状況: undefined,
    申請承認: pendingAttendanceApplicationsCount,
    メンバー管理: pendingCompanyApplicationsCount,
    データ出力: undefined,
    管理者設定: undefined,
    有休管理: undefined,
    打刻: undefined,
    "履歴・修正": undefined,
    マイページ: undefined,
  }

  return (
    <div className="fixed inset-y-0 left-0 hidden gap-6 overflow-auto bg-background md:z-50 md:flex md:flex-col md:items-start md:justify-between md:border-r md:border-border">
      <ul className="flex w-[240px] flex-col items-start gap-y-1 p-4">
        {children}
        <Separator
          style={{ width: "calc(100% - 2rem)" }}
          className="mx-auto my-2"
        />
        {siteConfig.mainNav.map((item) => {
          const icon = icons[item.title] || Clock

          return (
            <li key={item.title} className="w-full">
              <NavbarItem
                label={item.title}
                icon={icon}
                relativePath={item.relativePath}
              />
            </li>
          )
        })}
        {isAdmin && (
          <>
            <li className="w-full pt-8">
              <span className="space-y-2 pl-4 text-sm font-semibold text-muted-foreground">
                管理者
              </span>
            </li>
            {siteConfig.adminNav.map((item) => {
              const notificationCount =
                notificationCounts[item.title] || undefined
              const icon = icons[item.title] || List

              return (
                <li key={item.title} className="w-full">
                  <NavbarItem
                    label={item.title}
                    icon={icon}
                    relativePath={item.relativePath}
                    notificationCount={notificationCount}
                  />
                </li>
              )
            })}
          </>
        )}
      </ul>
      <div className="flex w-[240px] justify-between p-4">
        <Image
          src="/logo-and-horizontal-text.svg"
          alt="logo"
          width={120}
          height={32}
        />
        {/* <ModeToggle /> */}
      </div>
    </div>
  )
}
