import {
  ref as storageRef,
  type FirebaseStorage,
  type StorageReference,
} from "firebase/storage"

export class FirebaseStorageInfra {
  #storage: FirebaseStorage

  constructor(storage: FirebaseStorage) {
    this.#storage = storage
  }

  ref(path: string): StorageReference {
    return storageRef(this.#storage, path)
  }

  get refs() {
    return {
      companyMainImage: ({
        companyId,
        filename,
      }: {
        companyId: string
        filename: string
      }): StorageReference => {
        return this.ref(`Company/v2/companies/${companyId}/main/${filename}`)
      },
    }
  }
}
