import { AttendanceApplication } from "@/domain/features/company/v2/entities/attendance_application/attendance_application"
import { RedundantFromWorker } from "@/domain/features/company/v2/entities/redundant_from_worker/redundant_from_worker"
import { type FirebaseFirestoreInfra } from "@/infrastructure/firebase_firestore/core/firestore"
import {
  deleteDoc,
  doc,
  getDoc,
  getDocs,
  orderBy,
  query,
} from "firebase/firestore"

export class AttendanceApplicationRepository {
  constructor(private readonly firestore: FirebaseFirestoreInfra) {}

  private collectionReference(
    params: AttendanceApplication.firestore.ParameterOfCollectionPath
  ) {
    return this.firestore.collectionReference<AttendanceApplication>(
      AttendanceApplication.firestore.collectionPath(params)
    )
  }

  async delete({
    companyId,
    userId,
    applicationId,
  }: AttendanceApplication.firestore.ParameterOfCollectionPath & {
    applicationId: string
  }) {
    const documentReference = doc(
      this.collectionReference({ companyId, userId }),
      applicationId
    )

    await deleteDoc(documentReference)
  }

  async fetch({
    companyId,
    userId,
    applicationId,
  }: AttendanceApplication.firestore.ParameterOfCollectionPath & {
    applicationId: string
  }) {
    const documentReference = doc(
      this.collectionReference({ companyId, userId }),
      applicationId
    )

    return (await getDoc(documentReference)).data()
  }

  async listInCompany({ companyId }: { companyId: string }) {
    const collectionReference = this.firestore.collectionReference<
      RedundantFromWorker<AttendanceApplication>
    >(
      RedundantFromWorker.AttendanceApplication.firestore.collectionPath({
        companyId,
      })
    )

    const ref = query(collectionReference, orderBy("data.createdAt", "desc"))

    const snapshot = await getDocs(ref)

    return snapshot.docs.map((e) => e.data())
  }

  async listByUser({
    companyId,
    userId,
  }: AttendanceApplication.firestore.ParameterOfCollectionPath) {
    const collectionReference = this.collectionReference({ companyId, userId })

    const ref = query(collectionReference, orderBy("createdAt", "desc"))

    const snapshot = await getDocs(ref)

    return snapshot.docs.map((e) => e.data())
  }

  async fetchPendingApplication({ path }: { path: string | undefined }) {
    if (path == null) {
      return undefined
    }

    const documentReference =
      this.firestore.documentReference<AttendanceApplication>(path)

    return (await getDoc(documentReference)).data()
  }
}
