/**
 * 企業への参加申請
 */
export interface JoiningCompanyApplication {
  joiningCompanyApplicationId: string
  status: JoiningCompanyApplicationStatus
  companyId: string
  createdAt: Date
  updatedAt: Date
  firstName: string
  lastName: string
}

export type JoiningCompanyApplicationStatus =
  | "approved"
  | "pending"
  | "rejected"

export namespace JoiningCompanyApplication {
  export namespace firestore {
    export const collectionPath = ({ userId }: { userId: string }) =>
      `Company/v2/users/${userId}/joiningCompanyApplications`
    export type ParameterOfCollectionPath = Parameters<typeof collectionPath>[0]
    export const collectionId = "joiningCompanyApplications"
    export const documentPath = ({
      userId,
      joiningCompanyApplicationId,
    }: {
      userId: string
      joiningCompanyApplicationId: string
    }) =>
      `Company/v2/users/${userId}/joiningCompanyApplications/${joiningCompanyApplicationId}`
    export type ParameterOfDocumentPath = Parameters<typeof documentPath>[0]
  }
}
